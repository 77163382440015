.live-coupon-img-root {
  /* position: relative; */
  /* display: flex; */
  flex-direction: column;
  width: 104px;
  align-items: flex-end;
}

.live-coupon-img-container {
  width: 96px;
  height: 48px;
  padding: 8px 0px;
  background-image: url("../../../../../public/images/icon-cpn.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.live-coupon-price {
  /* position: absolute;
  top: 39px;
  right: 10px; */
  padding-top: 4px;
  color: #fff;
  text-align: center;
}

.live-coupon-txt-num {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
}

.live-coupon-txt-rate {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.live-coupon-login-container {
  width: 96px;
  height: 40px;
  padding: 6px 17px;
  background-image: url("../../../../../public/images/btn-login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 10px;
}
