.viewer-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  height: 100%;
  z-index: 901;
}

.viewer-root-pc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  height: 100%;
  z-index: 901;
}

.small-layout {
  overflow: auto;
  font-size: 0;
  text-align: center;
}

.small-layout-inner {
  display: inline-block;
  width: 100%;
  max-width: 56.25vh;
  text-align: left;
}

.viewer-root-frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  height: 100%;
  z-index: 901;
}

.Live-Layout-wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.Live-Layout-wrapper-frame {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.Live-Layout-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.Live-Swipe-content {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
}

.VideoPlayer-wrapper {
  position: relative;
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  z-index: 902;
}

.live-header-root {
  width: 100%;
}

.live-header-wrapper {
  padding: 20px;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 997;
}

.Tap-screen-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 903;
}

.Tap-screen-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.live-content-root {
  margin-top: auto;
}

.live-content-wrapper {
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.live-content-chat {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
}

.live-content-notice {
  position: relative;
}

.live-content-pin-msg {
  position: relative;
}

.live-content-footer {
  margin-bottom: calc(-1 * constant(safe-area-inset-bottom));
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.live-content-th-absolute {
  margin-bottom: calc(-1 * constant(safe-area-inset-bottom));
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.live-user-comming-alert {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 10px;
}

.live-user-comming-alert-wrapper {
  margin-right: auto;
}

.live-alert-box {
  padding: 6px 15px;
  box-sizing: border-box;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.live-alert-box div {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: 13px;
}

.live-user-comming-alert-body {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.live-concurrent-view-body {
  background: rgba(255, 55, 109, 0.5);
  backdrop-filter: blur(5px);
}

.live-coupon-root {
  display: flex;
  flex-direction: row-reverse;
}

.live-content-game {
  position: relative;
}

.live-game-draw-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000099;
  width: 100%;
  height: 100%;
  /* z-index: 901; */
  z-index: 1001;
}

.frame-root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-poster {
  display: flex;
  width: 100%;
  height: 100%;
}
