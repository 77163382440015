:root {
  --wht: url(./assets/thehyundaiEmoji/logo-livart.svg);
  --blk: url(./assets/thehyundaiEmoji/obj-01.svg);
  --heart-pk: url(./assets/thehyundaiEmoji/obj-02.svg);
  --heart-wht: url(./assets/thehyundaiEmoji/obj-03.svg);
  --candy-yg: url(./assets/thehyundaiEmoji/obj-04.svg);
  --candy-pp: url(./assets/thehyundaiEmoji/obj-05.svg);
  --obj-06: url(./assets/thehyundaiEmoji/obj-06.svg);
  --obj-07: url(./assets/thehyundaiEmoji/obj-07.svg);
  --obj-08: url(./assets/thehyundaiEmoji/obj-08.svg);
  --footer-left-size: 75vw;
  --item-title-size: 14px;
  --item-price-size: 16px;
  --btn-new-chat-btn: 13px;
}

@media (max-width: 350px) {
  :root {
    --footer-left-size: 70vw;
    --item-title-size: 11px;
    --item-price-size: 13px;
    --btn-new-chat-btn: 11px;
  }
}

img {
  user-select: none !important;
  -webkit-user-drag: none;
}

.chat-bg-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.chat-bg-transparent {
  background: rgba(0, 0, 0, 0);
}
