.error-notice {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 1rem !important;
  font-weight: 700;
  z-index: 901;
  color: snow;
  white-space: pre-wrap;
  text-align: center;
  line-height: 40px;
}
