.broad-stop-remain-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 902;
}

.broad-stop-remain-item div {
  font-family: "Noto Sans KR";
}

.broad-stop-remain-live-mark img {
  width: 11vmin;
  height: 11vmin;
}

.broad-stop-remain-comming-soon {
  margin-top: 6px;
  font-family: "Noto Sans KR";
  font-weight: 900;
  font-size: 5vh;
  line-height: 1em;
}

.broad-stop-remain-broad-time {
  margin-top: 6px;
  font-weight: 400;
  font-size: 3vh;
  /* text-shadow: rgb(0 0 0 / 27%) 1px 1px; */
  line-height: 1em;
}

.broad-stop-remain-time {
  margin-top: 15px;
  box-sizing: border-box;
  background-color: #ff376d;
  border-radius: 27px;
  padding: 3px 4vmin;
  display: flex;
  align-items: center;
  font-size: 1.5vh;
  font-weight: 700;
}

.broad-stop-client-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;
  background-color: black;
  object-fit: cover;
  z-index: 800;
  transition: all 1s;
}
.broad-stop-client-video iframe,
.broad-stop-client-video object,
.broad-stop-client-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
