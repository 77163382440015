.sub_module_root {
  padding: 16px;
}

.sub_module_drawer_top {
  display: flex;
  align-items: center;
}

.sub_module_drawer_top_img {
  width: 18px;
  height: 22px;
}
.sub_module_drawer_top_text {
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  color: #111111;
}

.sub_module_drawer_close {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.sub_module_content {
  width: 100%;
  height: 48vh;
  overflow-x: hidden;
  overflow-y: scroll;
  word-break: break-all;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.sub_module_content::-webkit-scrollbar {
  display: none;
}

/* 상품 CSS */
:root {
  /* --pink-color: #ea5c84; */
  --rolling-box-size: 65px;
}

.live_product_now {
  color: #ffffff;
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: var(--item-title-size);
  /* line-height: 20px; */
  border-radius: 10px;
  padding: 2px 4px;
  background: #ed4166;
  text-align: center;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  top: 18px;
  left: -8px;
}

.live_more_body_box {
  width: 100%;
  height: 100%;
  background-color: "#fff";
}

.live_more_body_box_pc {
  width: 100%;
  height: calc(100% - 87px);
  overflow: hidden;
}

.prod_scroll {
  overflow-y: scroll;
  height: 100%;
}

.prod_scroll::-webkit-scrollbar {
  display: none;
}

.prod_scroll_pc {
  overflow-y: scroll;
  height: 100%;
}

.prod_scroll_pc::-webkit-scrollbar {
  display: none;
}

.prod_list {
  margin: 0;
  padding: 0;
}

.prod_item_box {
  display: flex;
  flex-direction: column;
  height: 120px;
  padding: 0px 20px;
  cursor: pointer;
}

.prod_item_info {
  display: flex;
  height: 100%;
  position: relative;
}

.prod_image_box {
  /* padding: 10px; */
  /* flex-basis: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.prod_image_box img {
  /* width: 100%; */
  width: 70px;
  height: 70px;
  border-radius: 7px;
}

.prod_desc_box {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  padding: 10px;
  justify-content: center;
}

.prod-desc-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.prod_desc_title_box {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 45px;
}

.prod_desc_title_2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

.color_red {
  color: red;
}

.line_through {
  text-decoration: line-through;
}

.color_lightgray {
  color: lightgray;
}

.original_price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.sale_price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.cpn_item_box {
  display: flex;
  flex-direction: column;
  height: 140px;
  padding: 0px 20px;
  cursor: pointer;
}

.cpn_item_stack {
  display: flex;
  align-items: center;
  height: 100%;
}

.cpn_scroll {
  overflow-y: scroll;
  height: 42vh;
}

.cpn_scroll::-webkit-scrollbar {
  display: none;
}

@media screen and (max-height: 670px) {
  .cpn_scroll {
    overflow-y: scroll;
    height: 34vh;
  }
}

@media screen and (min-height: 671px) and (max-height: 750px) {
  .cpn_scroll {
    overflow-y: scroll;
    height: 36vh;
  }
}

@media screen and (min-height: 751px) and (max-height: 800px) {
  .cpn_scroll {
    overflow-y: scroll;
    height: 38vh;
  }
}

.cpn_image_box {
  /* padding: 10px; */
  /* flex-basis: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cpn_image_box img {
  /* width: 100%; */
  width: 150px;
  height: 80px;
  border-radius: 7px;
}

.cpn_desc_box {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  height: 120px;
  padding: 10px;
  justify-content: center;
}

.cpn_normal_text {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

}

.cpn_bold_text {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

}

.cpn_small_text {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;

}

.cpn_scroll_pc {
  overflow-y: scroll;
  height: calc(100% - 52px);
}

.cpn_scroll_pc::-webkit-scrollbar {
  display: none;
}
