@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-display: swap;
  font-weight: 1;
  src: local("NanumBarunGothic"), local("NanumBarunGothic-Regular"), url("./fonts/NanumBarunGothic.woff") format("woff");
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 100; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Thin"), url("./fonts/NotoSansKR-Thin.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 300; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Light"), url("./fonts/NotoSansKR-Light.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Regular"), url("./fonts/NotoSansKR-Regular.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Medium"), url("./fonts/NotoSansKR-Medium.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Bold"), url("./fonts/NotoSansKR-Bold.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 900; 
  font-style: normal; 
  font-display: swap;
  src: local("NotoSansKR-Black"), url("./fonts/NotoSansKR-Black.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400; 
  font-style: normal; 
  font-display: swap;
  src: local("Roboto-Regular"), url("./fonts/Roboto-Regular.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700; 
  font-style: normal; 
  font-display: swap;
  src: local("Roboto-Bold"), url("./fonts/Roboto-Bold.otf") format('opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}