.sub-module-root {
  padding: 16px;
}

.sub-module-drawer-top {
  display: flex;
  align-items: center;
}

.sub-module-drawer-top-img {
  width: 18px;
  height: 22px;
}
.sub-module-drawer-top-text {
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  color: #111111;
}

.sub-module-drawer-close {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.sub-module-content {
  width: 100%;
  height: 42vh;
  overflow-x: hidden;
  overflow-y: scroll;
  word-break: break-all;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

@media screen and (max-height: 670px) {
  .sub-module-content {
    width: 100%;
    height: 34vh;
    overflow-x: hidden;
    overflow-y: scroll;
    word-break: break-all;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
}

@media screen and (min-height: 671px) and (max-height: 750px) {
  .sub-module-content {
    width: 100%;
    height: 36vh;
    overflow-x: hidden;
    overflow-y: scroll;
    word-break: break-all;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
}

@media screen and (min-height: 751px) and (max-height: 800px) {
  .sub-module-content {
    width: 100%;
    height: 38vh;
    overflow-x: hidden;
    overflow-y: scroll;
    word-break: break-all;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
}

.sub-module-content::-webkit-scrollbar {
  display: none;
}

.sub-module-content-text {
  font-family: Noto Sans KR !important;
  font-weight: 400;
  font-size: 14px !important;
  font-style: normal;
  color: #777777;
}

.sub-module-question-item {
  position: relative;
  left: 8px;
}
