#chat-root div,
#chat-root span {
  font-family: "noto sans KR";
}

.chat-root {
  padding-bottom: 0px;
  z-index: 900;
  /* width: calc(var(--footer-left-size) + 32px); */
  width: 80%;
}

.chat-root-pc {
  padding-bottom: 0px;
  z-index: 900;
  /* width: calc(var(--footer-left-size) + 32px); */
  width: 100%;
}

.gradient-box {
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}

.chat-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chat-list-pc {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  display: flex;
  flex-direction: column;
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.chat-list-pc::-webkit-scrollbar {
  display: none;
}

.btn-new-chat {
  position: absolute;
  /* top: 100%;
  left: 50%; */
  bottom: 5px;
  right: 25%;
  /* transform: translate(-50%, -183px); */
  z-index: 901;
}

.chat-input-root {
  filter: drop-shadow(2px 4px 6px #494949);
}

.chat-input-root-pc {
  filter: drop-shadow(0px 0px 9px #49494940);
  border-end-start-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
}

.chat-without-padding input {
  padding: 0px, 14px !important;
}

.chat-btn-icon {
  width: 32px;
  height: 28px;
  margin-bottom: 2px;
  margin-top: 8px;
}

.chat-btn-title {
  font-size: 12px;
}

.chat-list-body {
  text-align: left;
  display: flex;
}

#input-chat-button {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ff376d;
}

#input-chat {
  padding: 10px 5px;
  /* border-color: #dbdbdbb8; */
  height: 41px;
  box-sizing: border-box;
  /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
  background-color: #ffffff;
}

#input-chat fieldset {
  border: hidden;
}

#input-chat input#input-chat-Message {
  font-size: 0.8rem;
}
