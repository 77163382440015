.live-mute-text {
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@keyframes fadeout {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  15% {
    transform: translate(0);
    opacity: 1;
  }
  30% {
    transform: translate(0);
    opacity: 1;
  }
  90% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  15% {
    transform: translate(0);
    opacity: 1;
  }
  30% {
    transform: translate(0);
    opacity: 1;
  }
  90% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  15% {
    transform: translate(0);
    opacity: 1;
  }
  30% {
    transform: translate(0);
    opacity: 1;
  }
  90% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  15% {
    transform: translate(0);
    opacity: 1;
  }
  30% {
    transform: translate(0);
    opacity: 1;
  }
  90% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.live-mute-text-container {
  height: 100%;
  background-color: rgba(236, 20, 20, 0.6);
  opacity: 0.9;
  border-radius: 6.8px;
  padding: 2px 12px 2px 8px;
  display: flex;
  align-items: center;
  transition: ease-out;
  animation: fadeout 30s;
  -moz-animation: fadeout 30s; /* Firefox */
  -webkit-animation: fadeout 30s; /* Safari and Chrome */
  -o-animation: fadeout 30s; /* Opera */
}
.live-mute-text-img {
  position: relative;
  bottom: 1px;
  width: 13px;
  height: 13px;
}

.vod-select {
  padding: 2px 10px;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 2px;
  border-color: snow;
  border-radius: 20px;
  transition: all 1s;
  cursor: pointer;
}

.shake_bell {
  transform-origin: 50% 0%;
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}

.broad-title-live-mark {
  position: relative;
  font-weight: bold;
  margin-right: 5px;
  padding: 2px 7px;
  font-size: 11px;
  border-radius: 10px;
  text-shadow: 0 0 0 black;
  background: rgb(216, 60, 65);
  background: linear-gradient(90deg, rgba(216, 60, 65, 1) 0%, rgba(212, 99, 51, 1) 100%);
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.live-logo {
  width: 75px;
  /* height: 18px; */
  top: 8%;
  left: 4%;
}
.live-close {
  width: 15.84px;
  height: 18px;
}
.live-mute {
  width: 22.51px;
  height: 18px;
}
.live-viewer-icon {
  width: 12px;
  height: 11px;
}
.live-heart-icon {
  width: 11px;
  height: 11px;
  margin-left: 8px;
}
.live-count-text {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  margin-left: 3px;
}
.live-header-icon {
  display: flex;
  align-items: center;
}
