:root {
  --thd-green: #384e45;
  --thd-pink: #e6c6df;
}

.modal-share {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 56.25vh;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  border: 0;
  padding: 0;
  border-radius: 1rem;
}

.modal-share:focus-visible {
  outline: none;
}

.share-small-text {
  font-size: 0.8rem;
  text-align: center;
}

.share-icon {
  margin-top: 6px;
  margin-bottom: auto;
  width: 44px;
  height: 44px;
}

.share-title-icon {
  width: 20px;
  height: 24px;
}

.share-title-text {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans KR";
  line-height: 28px;
  color: #111111;
  margin-left: 6px;
}

.modal-top-border {
  display: flex;
  padding: 0;
  height: 6px;
}

.green-border {
  background-color: var(--thd-green);
  flex-basis: 35%;
}

.pink-border {
  background-color: var(--thd-pink);
  flex-basis: 65%;
}

.modal-content {
  padding: 20px;
}

.stack-row {
  width: 100%;
}

.item-small {
  width: 35px;
  height: 35px;
}
