.live-pin-msg-wrapper {
  padding: 0px 14px;
  height: 40.38px;
  transition: height 1s;
}

.live-pin-msg-root {
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6.8px;
  height: 100%;
  display: flex; /* 0104 허정은 변경 */
  align-items: center; /* 0104 허정은 변경 */
  border: none; /* 0104 허정은 변경 */
  transition: height 1s;
  z-index: 900;
  width: 80%;
  cursor: pointer;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.live-pin-msg-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.live-pin-msg-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.live-pin-msg-more {
  padding: 20px 0px;
  color: #777777;
}

.live-pin-msg-more-body {
  padding: 16px;
}

.live-pin-msg-drawer-top {
  display: flex;
  align-items: center;
}

.live-pin-msg-drawer-top-img {
  width: 18px;
  height: 22px;
}
.live-pin-msg-drawer-top-text {
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  color: #111111;
}
.live-pin-msg-drawer-close {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.live-pin-msg-content {
  font-family: Noto Sans KR !important;
  font-weight: 400;
  font-size: 14px !important;
  font-style: normal;
  color: #777777;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.live-pin-msg-content::-webkit-scrollbar {
  display: none;
}

.live-pin-msg-marquee-content {
  font-family: "Noto Sans KR" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}
