.live-game-list-root {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.live-game-list-root > .wrap {
  position: relative;
}

.live-game-list-root > .wrap > .header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
