.live-game-draw-wrapper {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 64.06%);
  transform-style: preserve-3d;
  perspective: 1000px;
  /* display: flex;
  justify-content: space-between; */
}

.live-game-draw-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.live-game-draw-img {
  height: 40px;
  width: 45px;
}

.live-game-draw-stack {
  position: relative;
  top: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-game-draw-apply-box {
  width: 80%;
  min-height: 30vh;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  margin-top: 20px;
  padding: 40px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

.live-game-draw-apply-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@keyframes flicker {
  0% {
    transform: skewX(5deg);
    box-shadow: 0 0 17px 10px rgba(251, 246, 190, 0.71);
  }
  25% {
    transform: skewX(-5deg);
    box-shadow: 0 0 17px 5px rgba(251, 246, 190, 0.71);
  }
  50% {
    transform: skewX(10deg);
    box-shadow: 0 0 17px 7px rgba(251, 246, 190, 0.71);
  }
  75% {
    transform: skewX(-10deg);
    box-shadow: 0 0 17px 5px rgba(251, 246, 190, 0.71);
  }
  100% {
    transform: skewX(5deg);
    box-shadow: 0 0 17px 10px rgba(251, 246, 190, 0.71);
  }
}
.pyro > .before,
.pyro > .after {
  position: fixed;
  top: 40%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 36px 15.3333333333px red, -95px -64.6666666667px #00bbff, 37px -143.6666666667px #2f00ff, 86px 23.3333333333px #37ff00,
      85px 77.3333333333px #ff0009, -141px -3.6666666667px #002fff, -114px -226.6666666667px #00ff6f, -82px -170.6666666667px #ff006f,
      -43px -19.6666666667px #00ffc4, 195px -152.6666666667px #9d00ff, -82px -278.6666666667px #ff1a00, -173px -231.6666666667px #00fff2,
      -123px -325.6666666667px #00ffb7, 230px -395.6666666667px #0099ff, -40px -199.6666666667px #e100ff, -75px -158.6666666667px #00fff2,
      155px 46.3333333333px #ff001e, -110px -314.6666666667px #b7ff00, 30px -64.6666666667px #4400ff, -30px -25.6666666667px #0026ff,
      -3px -41.6666666667px #aaff00, -111px -411.6666666667px #00ff59, -240px -337.6666666667px #ff0037, 58px -321.6666666667px #00ff44,
      175px 27.3333333333px #ff00b7, 36px 14.3333333333px #0055ff, -2px -2.6666666667px #1e00ff, 34px -205.6666666667px #0048ff,
      -28px -291.6666666667px #fffb00, 93px -125.6666666667px #00ffbb, 168px -175.6666666667px #f200ff, 150px -75.6666666667px #ff3700,
      -171px -307.6666666667px #0044ff, 114px -361.6666666667px #00f2ff, -31px -228.6666666667px #00d5ff, -225px -335.6666666667px #7300ff,
      -53px -291.6666666667px #7b00ff, 195px -59.6666666667px #1aff00, 164px -127.6666666667px #c4ff00, -60px -249.6666666667px #00ff95,
      -82px -253.6666666667px #0022ff, -164px -309.6666666667px #7b00ff, 35px -258.6666666667px #ff6f00, 194px 83.3333333333px #ff9d00,
      41px -316.6666666667px #ff9900, -202px -93.6666666667px #bb00ff, -28px -303.6666666667px #00aaff, 36px -402.6666666667px #a600ff,
      -34px -261.6666666667px #ffc800, 238px 70.3333333333px #ffc800, -196px -83.6666666667px #95ff00;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: 36px 15.3333333333px red, -95px -64.6666666667px #00bbff, 37px -143.6666666667px #2f00ff, 86px 23.3333333333px #37ff00,
      85px 77.3333333333px #ff0009, -141px -3.6666666667px #002fff, -114px -226.6666666667px #00ff6f, -82px -170.6666666667px #ff006f,
      -43px -19.6666666667px #00ffc4, 195px -152.6666666667px #9d00ff, -82px -278.6666666667px #ff1a00, -173px -231.6666666667px #00fff2,
      -123px -325.6666666667px #00ffb7, 230px -395.6666666667px #0099ff, -40px -199.6666666667px #e100ff, -75px -158.6666666667px #00fff2,
      155px 46.3333333333px #ff001e, -110px -314.6666666667px #b7ff00, 30px -64.6666666667px #4400ff, -30px -25.6666666667px #0026ff,
      -3px -41.6666666667px #aaff00, -111px -411.6666666667px #00ff59, -240px -337.6666666667px #ff0037, 58px -321.6666666667px #00ff44,
      175px 27.3333333333px #ff00b7, 36px 14.3333333333px #0055ff, -2px -2.6666666667px #1e00ff, 34px -205.6666666667px #0048ff,
      -28px -291.6666666667px #fffb00, 93px -125.6666666667px #00ffbb, 168px -175.6666666667px #f200ff, 150px -75.6666666667px #ff3700,
      -171px -307.6666666667px #0044ff, 114px -361.6666666667px #00f2ff, -31px -228.6666666667px #00d5ff, -225px -335.6666666667px #7300ff,
      -53px -291.6666666667px #7b00ff, 195px -59.6666666667px #1aff00, 164px -127.6666666667px #c4ff00, -60px -249.6666666667px #00ff95,
      -82px -253.6666666667px #0022ff, -164px -309.6666666667px #7b00ff, 35px -258.6666666667px #ff6f00, 194px 83.3333333333px #ff9d00,
      41px -316.6666666667px #ff9900, -202px -93.6666666667px #bb00ff, -28px -303.6666666667px #00aaff, 36px -402.6666666667px #a600ff,
      -34px -261.6666666667px #ffc800, 238px 70.3333333333px #ffc800, -196px -83.6666666667px #95ff00;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: 36px 15.3333333333px red, -95px -64.6666666667px #00bbff, 37px -143.6666666667px #2f00ff, 86px 23.3333333333px #37ff00,
      85px 77.3333333333px #ff0009, -141px -3.6666666667px #002fff, -114px -226.6666666667px #00ff6f, -82px -170.6666666667px #ff006f,
      -43px -19.6666666667px #00ffc4, 195px -152.6666666667px #9d00ff, -82px -278.6666666667px #ff1a00, -173px -231.6666666667px #00fff2,
      -123px -325.6666666667px #00ffb7, 230px -395.6666666667px #0099ff, -40px -199.6666666667px #e100ff, -75px -158.6666666667px #00fff2,
      155px 46.3333333333px #ff001e, -110px -314.6666666667px #b7ff00, 30px -64.6666666667px #4400ff, -30px -25.6666666667px #0026ff,
      -3px -41.6666666667px #aaff00, -111px -411.6666666667px #00ff59, -240px -337.6666666667px #ff0037, 58px -321.6666666667px #00ff44,
      175px 27.3333333333px #ff00b7, 36px 14.3333333333px #0055ff, -2px -2.6666666667px #1e00ff, 34px -205.6666666667px #0048ff,
      -28px -291.6666666667px #fffb00, 93px -125.6666666667px #00ffbb, 168px -175.6666666667px #f200ff, 150px -75.6666666667px #ff3700,
      -171px -307.6666666667px #0044ff, 114px -361.6666666667px #00f2ff, -31px -228.6666666667px #00d5ff, -225px -335.6666666667px #7300ff,
      -53px -291.6666666667px #7b00ff, 195px -59.6666666667px #1aff00, 164px -127.6666666667px #c4ff00, -60px -249.6666666667px #00ff95,
      -82px -253.6666666667px #0022ff, -164px -309.6666666667px #7b00ff, 35px -258.6666666667px #ff6f00, 194px 83.3333333333px #ff9d00,
      41px -316.6666666667px #ff9900, -202px -93.6666666667px #bb00ff, -28px -303.6666666667px #00aaff, 36px -402.6666666667px #a600ff,
      -34px -261.6666666667px #ffc800, 238px 70.3333333333px #ffc800, -196px -83.6666666667px #95ff00;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: 36px 15.3333333333px red, -95px -64.6666666667px #00bbff, 37px -143.6666666667px #2f00ff, 86px 23.3333333333px #37ff00,
      85px 77.3333333333px #ff0009, -141px -3.6666666667px #002fff, -114px -226.6666666667px #00ff6f, -82px -170.6666666667px #ff006f,
      -43px -19.6666666667px #00ffc4, 195px -152.6666666667px #9d00ff, -82px -278.6666666667px #ff1a00, -173px -231.6666666667px #00fff2,
      -123px -325.6666666667px #00ffb7, 230px -395.6666666667px #0099ff, -40px -199.6666666667px #e100ff, -75px -158.6666666667px #00fff2,
      155px 46.3333333333px #ff001e, -110px -314.6666666667px #b7ff00, 30px -64.6666666667px #4400ff, -30px -25.6666666667px #0026ff,
      -3px -41.6666666667px #aaff00, -111px -411.6666666667px #00ff59, -240px -337.6666666667px #ff0037, 58px -321.6666666667px #00ff44,
      175px 27.3333333333px #ff00b7, 36px 14.3333333333px #0055ff, -2px -2.6666666667px #1e00ff, 34px -205.6666666667px #0048ff,
      -28px -291.6666666667px #fffb00, 93px -125.6666666667px #00ffbb, 168px -175.6666666667px #f200ff, 150px -75.6666666667px #ff3700,
      -171px -307.6666666667px #0044ff, 114px -361.6666666667px #00f2ff, -31px -228.6666666667px #00d5ff, -225px -335.6666666667px #7300ff,
      -53px -291.6666666667px #7b00ff, 195px -59.6666666667px #1aff00, 164px -127.6666666667px #c4ff00, -60px -249.6666666667px #00ff95,
      -82px -253.6666666667px #0022ff, -164px -309.6666666667px #7b00ff, 35px -258.6666666667px #ff6f00, 194px 83.3333333333px #ff9d00,
      41px -316.6666666667px #ff9900, -202px -93.6666666667px #bb00ff, -28px -303.6666666667px #00aaff, 36px -402.6666666667px #a600ff,
      -34px -261.6666666667px #ffc800, 238px 70.3333333333px #ffc800, -196px -83.6666666667px #95ff00;
  }
}
@keyframes bang {
  to {
    box-shadow: 36px 15.3333333333px red, -95px -64.6666666667px #00bbff, 37px -143.6666666667px #2f00ff, 86px 23.3333333333px #37ff00,
      85px 77.3333333333px #ff0009, -141px -3.6666666667px #002fff, -114px -226.6666666667px #00ff6f, -82px -170.6666666667px #ff006f,
      -43px -19.6666666667px #00ffc4, 195px -152.6666666667px #9d00ff, -82px -278.6666666667px #ff1a00, -173px -231.6666666667px #00fff2,
      -123px -325.6666666667px #00ffb7, 230px -395.6666666667px #0099ff, -40px -199.6666666667px #e100ff, -75px -158.6666666667px #00fff2,
      155px 46.3333333333px #ff001e, -110px -314.6666666667px #b7ff00, 30px -64.6666666667px #4400ff, -30px -25.6666666667px #0026ff,
      -3px -41.6666666667px #aaff00, -111px -411.6666666667px #00ff59, -240px -337.6666666667px #ff0037, 58px -321.6666666667px #00ff44,
      175px 27.3333333333px #ff00b7, 36px 14.3333333333px #0055ff, -2px -2.6666666667px #1e00ff, 34px -205.6666666667px #0048ff,
      -28px -291.6666666667px #fffb00, 93px -125.6666666667px #00ffbb, 168px -175.6666666667px #f200ff, 150px -75.6666666667px #ff3700,
      -171px -307.6666666667px #0044ff, 114px -361.6666666667px #00f2ff, -31px -228.6666666667px #00d5ff, -225px -335.6666666667px #7300ff,
      -53px -291.6666666667px #7b00ff, 195px -59.6666666667px #1aff00, 164px -127.6666666667px #c4ff00, -60px -249.6666666667px #00ff95,
      -82px -253.6666666667px #0022ff, -164px -309.6666666667px #7b00ff, 35px -258.6666666667px #ff6f00, 194px 83.3333333333px #ff9d00,
      41px -316.6666666667px #ff9900, -202px -93.6666666667px #bb00ff, -28px -303.6666666667px #00aaff, 36px -402.6666666667px #a600ff,
      -34px -261.6666666667px #ffc800, 238px 70.3333333333px #ffc800, -196px -83.6666666667px #95ff00;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
