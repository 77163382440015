.coupon-complete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 120px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  border: 0;
  border-radius: 8px;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon-complete-modal-content {
  width: 100%;
  padding: 15px 15px;
}

.coupon-complete-header-blank {
  position: relative;
  width: 100%;
  height: 100%;
}
.coupon-complete-content-blank {
  position: relative;
  width: 100%;
  height: 10px;
}

.coupon-complete-text {
  text-align: center;
}
