/* css에 삽입하는 방법 */
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}

div,
span {
  font-family: "Noto Sans KR";
}
a.nav-link {
  text-decoration: none;
  color: black;
}

a.activated > div {
  background-color: rgb(158, 158, 158);
  color: white;
}

a.activated > div:hover {
  background-color: rgb(124, 124, 124);
  color: white;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.display-none {
  display: none;
}

.color-snow {
  color: snow;
}

.color-orangered {
  color: orangered;
}

.color-dark {
  color: rgb(72, 68, 66);
}

.font-small {
  font-size: small;
}

.bottom-50 {
  bottom: 50px !important;
}

.flex {
  display: flex;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.fl-ed {
  justify-content: flex-end;
}

.sp-bt {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.bt-md {
  height: 40px;
}

.topMg-10 {
  margin-top: 10px;
}

.bottomMg-10 {
  margin-bottom: 10px;
}

.rightMg-10 {
  margin-right: 10px;
}

.pd-0 {
  padding: 0px;
}

.pd-40 {
  padding: 40px;
}

.pd-100-h {
  padding-left: 100px;
  padding-right: 100px;
}

.pos-relative {
  position: relative;
}

.loading-circle-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.width100 {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-17 {
  margin-bottom: 17px;
}

.white-shadow {
  filter: drop-shadow(0px 0px 1px #494949);
}

.text-shadow {
  text-shadow: 0 0 #232325;
}

.zindex100 {
  z-index: 100;
}

.height-100 {
  height: 100%;
}

.broder-radius-05 {
  border-radius: 0.5rem;
}

.pb-10 {
  padding-bottom: 10px;
}

.live-more-body {
  padding: 16px;
}

.noScrollBar {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.noScrollBar::-webkit-scrollbar {
  display: none;
}
