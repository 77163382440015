.like-wrapper {
  position: relative;
  user-select: none;
  overflow-x: none;
}

.heart_root {
  position: relative;
  bottom: 0;
  right: 0px;
}

.heart-actions {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 860;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100px;
  height: 300px;
  transform: translateX(10px);
}

#divheartActions {
  position: absolute;
  bottom: 0;
  right: -40px;
  z-index: 860;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 140px;
  height: 300px;
  transform: translateX(10px);
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  mask-image: linear-gradient(to left, rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(to left, rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}
