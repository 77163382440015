:root {
  /* --pink-color: #ea5c84; */
  --rolling-box-size: 65px;
}

.prod-circle-button-box {
  /* width: var(--footer-left-size); */
  width: 80%;
  height: var(--rolling-box-size);
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.prod-circle-button {
  overflow: hidden;
  /* width: 50px; */
  white-space: nowrap;
  /* position: relative; */
  width: 80%;
}

div.slider-circle {
  /* width: 52px; */
  height: var(--rolling-box-size);
  /* display: flex; */
  flex-direction: column;
  flex-wrap: nowrap;
  user-select: none;
}

.slider-circle div.slideItem {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #111111;
  padding-left: 10px;
  overflow: hidden;
  position: relative;
}

img.item-thumb {
  object-fit: contain;
  width: 43px;
  height: 43px;
  border-radius: 6px;
}

.item-desc {
  overflow: hidden;
  margin-left: 10px;
}

.item-title {
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: var(--item-title-size);
  line-height: 20px;
  flex-wrap: nowrap;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.live-product-now {
  color: #ffffff;
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: var(--item-title-size);
  /* line-height: 20px; */
  border-radius: 10px;
  padding: 2px 4px;
  background: #ed4166;
  text-align: center;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  top: 18px;
  left: -8px;
}

.live-product-rolling-now {
  color: #ffffff;
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: var(--item-title-size);
  /* line-height: 20px; */
  border-radius: 8px;
  padding: 0px 5px;
  background: #ed4166;
  text-align: center;
  display: inline-block;
  position: absolute;
  font-size: 6px;
  line-height: 15px;
  top: 6px;
  left: 3px;
}

.item-price {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: var(--item-price-size);
  line-height: 23px;
}

.prod-circle-cnt {
  color: #999999;
  font-size: 18px;
  display: flex;
  padding: 1px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.prod-circle-cnt span {
  font-family: "Noto Sans KR";
}

.live-more-body-box {
  width: 100%;
  height: 520px;
  background-color: "white";
}

.live-more-body-box-pc {
  width: 100%;
  height: calc(100% - 87px);
  background-color: "white";
}

.prod-scroll {
  overflow-y: scroll;
  height: 430px;
}

.prod-scroll::-webkit-scrollbar {
  display: none;
}

.prod-scroll-pc {
  overflow-y: scroll;
  height: 100%;
}

.prod-scroll-pc::-webkit-scrollbar {
  display: none;
}

.prod-list-header {
  padding: 18px 15px 15px 20px;
  display: flex;
  justify-content: space-between;
}

.prod-list-header-title {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.prod-list-header span {
  color: #999999;
  font-family: "Noto Sans KR";
  font-weight: 400;
  margin-left: 7px;
}

.prod-list {
  margin: 0;
  padding: 0;
}

.prod-item-box {
  display: flex;
  flex-direction: column;
  height: 120px;
  padding: 0px 20px;
  cursor: pointer;
}

.prod-item-info {
  display: flex;
  height: 100%;
  position: relative;
}

.prod-image-box {
  /* padding: 10px; */
  /* flex-basis: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.prod-image-box img {
  /* width: 100%; */
  width: 70px;
  height: 70px;
  border-radius: 7px;
}

.prod-desc-box {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  padding: 10px;
  justify-content: center;
}

.prod-desc-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.prod-desc-title-box {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 45px;
}

.prod-desc-title-2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

.prod-desc-price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
}

.thd-sold-out-filter {
  display: flex;
  background-color: #cfcfcf58;
  width: 100%;
  height: 100%;
}

.color-red {
  color: red;
}

.line-through {
  text-decoration: line-through;
}

.color-lightgray {
  color: lightgray;
}

.original-price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.sale-price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
