.share-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 901;
}
