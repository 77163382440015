.client-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;
  background-color: black;
  object-fit: cover;
  z-index: 800;
  transition: all 1s;
}
.client-video iframe,
.client-video object,
.client-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-controls {
  right: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  margin-right: 8px;
  transition: all 0.2s ease;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); */
  z-index: 801;
}

.video-controls.hide {
  opacity: 0;
  pointer-events: none;
}

.svc-text {
  vertical-align: middle;
  text-align: center;
}

.vod-screen-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  bottom: 0px;
  right: 0px;
  z-index: 801;
}
